<template>
  <l-map :center="averageLocation" :options="mapOptions" :zoom="zoom">
    <l-tile-layer :url="url" />

    <template v-for="{ locations, courier } in pickup_points_by_courier">
      <l-marker
        v-for="location in locations"
        :key="location.id"
        :ref="`marker-${location.id}`"
        :lat-lng="{
          lng: location.geo.longitude,
          lat: location.geo.latitude,
        }"
        @click="setSelectedLocation(location)"
      >
        <l-icon
          :class-name="cn('tw-group/marker', selectedLocation?.id === location.id && '!tw-pointer-events-none')"
          :icon-anchor="[24, 48]"
          :icon-size="[48, 48]"
          :popup-anchor="[0, -48]"
        >
          <svg
            class="tw-fill-white group-hover/marker:tw-scale-125 tw-origin-bottom tw-transition-transform tw-w-12 tw-h-12 tw-stroke-black"
            :class="{
              'tw-scale-125 tw-stroke-primary': selectedLocation?.id === location.id,
            }"
            fill="none"
            height="100%"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
            width="100%"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z"></path>
            <circle cx="12" cy="10" r="3"></circle>
          </svg>
        </l-icon>
        <l-popup
          :options="{
            closeButton: true,
            closeOnClick: false,
            autoClose: false,
            closeOnEscapeKey: false,
          }"
        >
          <div class="tw-flex tw-flex-col tw-gap-4">
            <address>
              <h3 class="tw-text-lg tw-font-semibold">{{ location.name }}</h3>
            </address>
            <OpeningHoursTable class="tw-min-w-64 tw-text-xs md:tw-hidden" :location="location" />
          </div>
        </l-popup>
      </l-marker>
    </template>
  </l-map>
</template>

<script>
  import { cn } from '@/utils/functions';
  import { LPopup, LIcon, LMap, LMarker, LTileLayer } from 'vue2-leaflet';
  import { DAYS } from '@/constants/DAYS';
  import OpeningHoursTable from '@/components/form/pickup/OpeningHoursTable.vue';

  export default {
    components: {
      OpeningHoursTable,
      LIcon,
      LMap,
      LMarker,
      LPopup,
      LTileLayer,
    },
    data() {
      return {
        DAYS,
        mapOptions: {
          attributionControl: false,
        },
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        zoom: 15,
      };
    },
    methods: { cn },
    computed: {
      averageLocation() {
        let totalLatitude = 0;
        let totalLongitude = 0;
        let totalLocations = 0;

        this.pickup_points_by_courier.forEach(({ locations }) => {
          if (!locations) return;
          totalLocations += locations.length;
          locations.forEach(location => {
            totalLatitude += location.geo.latitude;
            totalLongitude += location.geo.longitude;
          });
        });

        const averageLatitude = totalLatitude / totalLocations;
        const averageLongitude = totalLongitude / totalLocations;

        return [averageLatitude || 0, averageLongitude || 0];
      },
    },
    inject: ['pickup_points_by_courier', 'selectedLocation', 'setSelectedLocation', 'setConfirmedLocation'],
    watch: {
      selectedLocation(location, oldLocation) {
        if (oldLocation) this.$refs[`marker-${oldLocation.id}`]?.[0]?.mapObject?.closePopup();

        this.$refs[`marker-${location.id}`][0]?.mapObject?.openPopup();
      },
    },
  };
</script>

<style lang="css">
  @import 'leaflet/dist/leaflet.css';
</style>
